import { lazy } from "react";

export default {
  "1861": {
    name: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861.svg" */"./1861"))
  },
  "1867": {
    name: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867.svg" */"./1867"))
  },
  "boat": {
    name: "boat",
    Component: lazy(() => import(/* webpackChunkName: "boat.svg" */"./boat"))
  },
  "bridge": {
    name: "bridge",
    Component: lazy(() => import(/* webpackChunkName: "bridge.svg" */"./bridge"))
  },
  "cactus": {
    name: "cactus",
    Component: lazy(() => import(/* webpackChunkName: "cactus.svg" */"./cactus"))
  },
  "cannon": {
    name: "cannon",
    Component: lazy(() => import(/* webpackChunkName: "cannon.svg" */"./cannon"))
  },
  "charter": {
    name: "charter",
    Component: lazy(() => import(/* webpackChunkName: "charter.svg" */"./charter"))
  },
  "coal": {
    name: "coal",
    Component: lazy(() => import(/* webpackChunkName: "coal.svg" */"./coal"))
  },
  "coalcar": {
    name: "coalcar",
    Component: lazy(() => import(/* webpackChunkName: "coalcar.svg" */"./coalcar"))
  },
  "cow-skull": {
    name: "cow-skull",
    Component: lazy(() => import(/* webpackChunkName: "cow-skull.svg" */"./cow-skull"))
  },
  "cylinder": {
    name: "cylinder",
    Component: lazy(() => import(/* webpackChunkName: "cylinder.svg" */"./cylinder"))
  },
  "ferry": {
    name: "ferry",
    Component: lazy(() => import(/* webpackChunkName: "ferry.svg" */"./ferry"))
  },
  "fish": {
    name: "fish",
    Component: lazy(() => import(/* webpackChunkName: "fish.svg" */"./fish"))
  },
  "home": {
    name: "home",
    Component: lazy(() => import(/* webpackChunkName: "home.svg" */"./home"))
  },
  "lock": {
    name: "lock",
    Component: lazy(() => import(/* webpackChunkName: "lock.svg" */"./lock"))
  },
  "mail": {
    name: "mail",
    Component: lazy(() => import(/* webpackChunkName: "mail.svg" */"./mail"))
  },
  "meat": {
    name: "meat",
    Component: lazy(() => import(/* webpackChunkName: "meat.svg" */"./meat"))
  },
  "medium-city": {
    name: "medium-city",
    Component: lazy(() => import(/* webpackChunkName: "medium-city.svg" */"./medium-city"))
  },
  "mining": {
    name: "mining",
    Component: lazy(() => import(/* webpackChunkName: "mining.svg" */"./mining"))
  },
  "mountain": {
    name: "mountain",
    Component: lazy(() => import(/* webpackChunkName: "mountain.svg" */"./mountain"))
  },
  "oil-derrick": {
    name: "oil-derrick",
    Component: lazy(() => import(/* webpackChunkName: "oil-derrick.svg" */"./oil-derrick"))
  },
  "oilbarrel": {
    name: "oilbarrel",
    Component: lazy(() => import(/* webpackChunkName: "oilbarrel.svg" */"./oilbarrel"))
  },
  "orange": {
    name: "orange",
    Component: lazy(() => import(/* webpackChunkName: "orange.svg" */"./orange"))
  },
  "port": {
    name: "port",
    Component: lazy(() => import(/* webpackChunkName: "port.svg" */"./port"))
  },
  "refinery": {
    name: "refinery",
    Component: lazy(() => import(/* webpackChunkName: "refinery.svg" */"./refinery"))
  },
  "river": {
    name: "river",
    Component: lazy(() => import(/* webpackChunkName: "river.svg" */"./river"))
  },
  "rocket": {
    name: "rocket",
    Component: lazy(() => import(/* webpackChunkName: "rocket.svg" */"./rocket"))
  },
  "round3": {
    name: "round3",
    Component: lazy(() => import(/* webpackChunkName: "round3.svg" */"./round3"))
  },
  "round4": {
    name: "round4",
    Component: lazy(() => import(/* webpackChunkName: "round4.svg" */"./round4"))
  },
  "round5": {
    name: "round5",
    Component: lazy(() => import(/* webpackChunkName: "round5.svg" */"./round5"))
  },
  "share": {
    name: "share",
    Component: lazy(() => import(/* webpackChunkName: "share.svg" */"./share"))
  },
  "steamboat": {
    name: "steamboat",
    Component: lazy(() => import(/* webpackChunkName: "steamboat.svg" */"./steamboat"))
  },
  "steel": {
    name: "steel",
    Component: lazy(() => import(/* webpackChunkName: "steel.svg" */"./steel"))
  },
  "swamp": {
    name: "swamp",
    Component: lazy(() => import(/* webpackChunkName: "swamp.svg" */"./swamp"))
  },
  "token": {
    name: "token",
    Component: lazy(() => import(/* webpackChunkName: "token.svg" */"./token"))
  },
  "tracks": {
    name: "tracks",
    Component: lazy(() => import(/* webpackChunkName: "tracks.svg" */"./tracks"))
  },
  "train": {
    name: "train",
    Component: lazy(() => import(/* webpackChunkName: "train.svg" */"./train"))
  },
  "tree": {
    name: "tree",
    Component: lazy(() => import(/* webpackChunkName: "tree.svg" */"./tree"))
  },
  "tunnel": {
    name: "tunnel",
    Component: lazy(() => import(/* webpackChunkName: "tunnel.svg" */"./tunnel"))
  },
  "water": {
    name: "water",
    Component: lazy(() => import(/* webpackChunkName: "water.svg" */"./water"))
  },
  "wheat": {
    name: "wheat",
    Component: lazy(() => import(/* webpackChunkName: "wheat.svg" */"./wheat"))
  }
};
