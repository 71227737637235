import { lazy } from "react";

export default {
  "1830/BM": {
    name: "BM",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/BM.svg" */"./1830/BM"))
  },
  "1830/BO": {
    name: "BO",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/BO.svg" */"./1830/BO"))
  },
  "1830/CO": {
    name: "CO",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/CO.svg" */"./1830/CO"))
  },
  "1830/CPR": {
    name: "CPR",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/CPR.svg" */"./1830/CPR"))
  },
  "1830/ERIE": {
    name: "ERIE",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/ERIE.svg" */"./1830/ERIE"))
  },
  "1830/NYC": {
    name: "NYC",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/NYC.svg" */"./1830/NYC"))
  },
  "1830/NYNH": {
    name: "NYNH",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/NYNH.svg" */"./1830/NYNH"))
  },
  "1830/PRR": {
    name: "PRR",
    group: "1830",
    Component: lazy(() => import(/* webpackChunkName: "1830/PRR.svg" */"./1830/PRR"))
  },
  "1830Mayfair/BM": {
    name: "BM",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/BM.svg" */"./1830Mayfair/BM"))
  },
  "1830Mayfair/BO": {
    name: "BO",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/BO.svg" */"./1830Mayfair/BO"))
  },
  "1830Mayfair/CO": {
    name: "CO",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/CO.svg" */"./1830Mayfair/CO"))
  },
  "1830Mayfair/CPR": {
    name: "CPR",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/CPR.svg" */"./1830Mayfair/CPR"))
  },
  "1830Mayfair/ERIE": {
    name: "ERIE",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/ERIE.svg" */"./1830Mayfair/ERIE"))
  },
  "1830Mayfair/NNH": {
    name: "NNH",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/NNH.svg" */"./1830Mayfair/NNH"))
  },
  "1830Mayfair/NYC": {
    name: "NYC",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/NYC.svg" */"./1830Mayfair/NYC"))
  },
  "1830Mayfair/PRR": {
    name: "PRR",
    group: "1830Mayfair",
    Component: lazy(() => import(/* webpackChunkName: "1830Mayfair/PRR.svg" */"./1830Mayfair/PRR"))
  },
  "1831/BA": {
    name: "BA",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/BA.svg" */"./1831/BA"))
  },
  "1831/BRP": {
    name: "BRP",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/BRP.svg" */"./1831/BRP"))
  },
  "1831/CP": {
    name: "CP",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/CP.svg" */"./1831/CP"))
  },
  "1831/EBT": {
    name: "EBT",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/EBT.svg" */"./1831/EBT"))
  },
  "1831/LIRR": {
    name: "LIRR",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/LIRR.svg" */"./1831/LIRR"))
  },
  "1831/LNE": {
    name: "LNE",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/LNE.svg" */"./1831/LNE"))
  },
  "1831/NKP": {
    name: "NKP",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/NKP.svg" */"./1831/NKP"))
  },
  "1831/NYCHR": {
    name: "NYCHR",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/NYCHR.svg" */"./1831/NYCHR"))
  },
  "1831/PM": {
    name: "PM",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/PM.svg" */"./1831/PM"))
  },
  "1831/THB": {
    name: "THB",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/THB.svg" */"./1831/THB"))
  },
  "1831/WMR": {
    name: "WMR",
    group: "1831",
    Component: lazy(() => import(/* webpackChunkName: "1831/WMR.svg" */"./1831/WMR"))
  },
  "1832/ACL": {
    name: "ACL",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/ACL.svg" */"./1832/ACL"))
  },
  "1832/AMTK": {
    name: "AMTK",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/AMTK.svg" */"./1832/AMTK"))
  },
  "1832/AWP": {
    name: "AWP",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/AWP.svg" */"./1832/AWP"))
  },
  "1832/BNSF": {
    name: "BNSF",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/BNSF.svg" */"./1832/BNSF"))
  },
  "1832/COG": {
    name: "COG",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/COG.svg" */"./1832/COG"))
  },
  "1832/CSX": {
    name: "CSX",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/CSX.svg" */"./1832/CSX"))
  },
  "1832/FECR": {
    name: "FECR",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/FECR.svg" */"./1832/FECR"))
  },
  "1832/GMO": {
    name: "GMO",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/GMO.svg" */"./1832/GMO"))
  },
  "1832/GRR": {
    name: "GRR",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/GRR.svg" */"./1832/GRR"))
  },
  "1832/IC": {
    name: "IC",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/IC.svg" */"./1832/IC"))
  },
  "1832/LN": {
    name: "LN",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/LN.svg" */"./1832/LN"))
  },
  "1832/NS": {
    name: "NS",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/NS.svg" */"./1832/NS"))
  },
  "1832/NW": {
    name: "NW",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/NW.svg" */"./1832/NW"))
  },
  "1832/SALR": {
    name: "SALR",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/SALR.svg" */"./1832/SALR"))
  },
  "1832/SCL": {
    name: "SCL",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/SCL.svg" */"./1832/SCL"))
  },
  "1832/SOU": {
    name: "SOU",
    group: "1832",
    Component: lazy(() => import(/* webpackChunkName: "1832/SOU.svg" */"./1832/SOU"))
  },
  "1846/BO": {
    name: "BO",
    group: "1846",
    Component: lazy(() => import(/* webpackChunkName: "1846/BO.svg" */"./1846/BO"))
  },
  "1846/CO": {
    name: "CO",
    group: "1846",
    Component: lazy(() => import(/* webpackChunkName: "1846/CO.svg" */"./1846/CO"))
  },
  "1846/ERIE": {
    name: "ERIE",
    group: "1846",
    Component: lazy(() => import(/* webpackChunkName: "1846/ERIE.svg" */"./1846/ERIE"))
  },
  "1846/GT": {
    name: "GT",
    group: "1846",
    Component: lazy(() => import(/* webpackChunkName: "1846/GT.svg" */"./1846/GT"))
  },
  "1846/IC": {
    name: "IC",
    group: "1846",
    Component: lazy(() => import(/* webpackChunkName: "1846/IC.svg" */"./1846/IC"))
  },
  "1846/NYC": {
    name: "NYC",
    group: "1846",
    Component: lazy(() => import(/* webpackChunkName: "1846/NYC.svg" */"./1846/NYC"))
  },
  "1846/PRR": {
    name: "PRR",
    group: "1846",
    Component: lazy(() => import(/* webpackChunkName: "1846/PRR.svg" */"./1846/PRR"))
  },
  "1848/BOE": {
    name: "BOE",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/BOE.svg" */"./1848/BOE"))
  },
  "1848/CAR": {
    name: "CAR",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/CAR.svg" */"./1848/CAR"))
  },
  "1848/CR": {
    name: "CR",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/CR.svg" */"./1848/CR"))
  },
  "1848/FT": {
    name: "FT",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/FT.svg" */"./1848/FT"))
  },
  "1848/NSW": {
    name: "NSW",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/NSW.svg" */"./1848/NSW"))
  },
  "1848/QR": {
    name: "QR",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/QR.svg" */"./1848/QR"))
  },
  "1848/SAR": {
    name: "SAR",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/SAR.svg" */"./1848/SAR"))
  },
  "1848/VR": {
    name: "VR",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/VR.svg" */"./1848/VR"))
  },
  "1848/WA": {
    name: "WA",
    group: "1848",
    Component: lazy(() => import(/* webpackChunkName: "1848/WA.svg" */"./1848/WA"))
  },
  "1849/AFG": {
    name: "AFG",
    group: "1849",
    Component: lazy(() => import(/* webpackChunkName: "1849/AFG.svg" */"./1849/AFG"))
  },
  "1849/ATA": {
    name: "ATA",
    group: "1849",
    Component: lazy(() => import(/* webpackChunkName: "1849/ATA.svg" */"./1849/ATA"))
  },
  "1849/CTL": {
    name: "CTL",
    group: "1849",
    Component: lazy(() => import(/* webpackChunkName: "1849/CTL.svg" */"./1849/CTL"))
  },
  "1849/IFT": {
    name: "IFT",
    group: "1849",
    Component: lazy(() => import(/* webpackChunkName: "1849/IFT.svg" */"./1849/IFT"))
  },
  "1849/RCS": {
    name: "RCS",
    group: "1849",
    Component: lazy(() => import(/* webpackChunkName: "1849/RCS.svg" */"./1849/RCS"))
  },
  "1849/SFA": {
    name: "SFA",
    group: "1849",
    Component: lazy(() => import(/* webpackChunkName: "1849/SFA.svg" */"./1849/SFA"))
  },
  "1861/E": {
    name: "E",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/E.svg" */"./1861/E"))
  },
  "1861/GR": {
    name: "GR",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/GR.svg" */"./1861/GR"))
  },
  "1861/KB": {
    name: "KB",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/KB.svg" */"./1861/KB"))
  },
  "1861/KK": {
    name: "KK",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/KK.svg" */"./1861/KK"))
  },
  "1861/KR": {
    name: "KR",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/KR.svg" */"./1861/KR"))
  },
  "1861/MB": {
    name: "MB",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MB.svg" */"./1861/MB"))
  },
  "1861/MK": {
    name: "MK",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MK.svg" */"./1861/MK"))
  },
  "1861/MKN": {
    name: "MKN",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MKN.svg" */"./1861/MKN"))
  },
  "1861/MKu": {
    name: "MKu",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MKu.svg" */"./1861/MKu"))
  },
  "1861/MKV": {
    name: "MKV",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MKV.svg" */"./1861/MKV"))
  },
  "1861/MNN": {
    name: "MNN",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MNN.svg" */"./1861/MNN"))
  },
  "1861/MV": {
    name: "MV",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MV.svg" */"./1861/MV"))
  },
  "1861/MVR": {
    name: "MVR",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/MVR.svg" */"./1861/MVR"))
  },
  "1861/N": {
    name: "N",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/N.svg" */"./1861/N"))
  },
  "1861/NW": {
    name: "NW",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/NW.svg" */"./1861/NW"))
  },
  "1861/OK": {
    name: "OK",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/OK.svg" */"./1861/OK"))
  },
  "1861/RO": {
    name: "RO",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/RO.svg" */"./1861/RO"))
  },
  "1861/RSR": {
    name: "RSR",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/RSR.svg" */"./1861/RSR"))
  },
  "1861/SE": {
    name: "SE",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/SE.svg" */"./1861/SE"))
  },
  "1861/SPW": {
    name: "SPW",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/SPW.svg" */"./1861/SPW"))
  },
  "1861/SV": {
    name: "SV",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/SV.svg" */"./1861/SV"))
  },
  "1861/SW": {
    name: "SW",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/SW.svg" */"./1861/SW"))
  },
  "1861/TR": {
    name: "TR",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/TR.svg" */"./1861/TR"))
  },
  "1861/V": {
    name: "V",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/V.svg" */"./1861/V"))
  },
  "1861/Y": {
    name: "Y",
    group: "1861",
    Component: lazy(() => import(/* webpackChunkName: "1861/Y.svg" */"./1861/Y"))
  },
  "1862/ATSF": {
    name: "ATSF",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/ATSF.svg" */"./1862/ATSF"))
  },
  "1862/CanP": {
    name: "CanP",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/CanP.svg" */"./1862/CanP"))
  },
  "1862/CN": {
    name: "CN",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/CN.svg" */"./1862/CN"))
  },
  "1862/COPR": {
    name: "COPR",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/COPR.svg" */"./1862/COPR"))
  },
  "1862/CP": {
    name: "CP",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/CP.svg" */"./1862/CP"))
  },
  "1862/GMO": {
    name: "GMO",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/GMO.svg" */"./1862/GMO"))
  },
  "1862/NP": {
    name: "NP",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/NP.svg" */"./1862/NP"))
  },
  "1862/NYC": {
    name: "NYC",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/NYC.svg" */"./1862/NYC"))
  },
  "1862/NYH": {
    name: "NYH",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/NYH.svg" */"./1862/NYH"))
  },
  "1862/SP": {
    name: "SP",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/SP.svg" */"./1862/SP"))
  },
  "1862/TP": {
    name: "TP",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/TP.svg" */"./1862/TP"))
  },
  "1862/UP": {
    name: "UP",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/UP.svg" */"./1862/UP"))
  },
  "1862/WP-FRR": {
    name: "WP-FRR",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/WP-FRR.svg" */"./1862/WP-FRR"))
  },
  "1862/WP": {
    name: "WP",
    group: "1862",
    Component: lazy(() => import(/* webpackChunkName: "1862/WP.svg" */"./1862/WP"))
  },
  "1867/AE": {
    name: "AE",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/AE.svg" */"./1867/AE"))
  },
  "1867/BBG": {
    name: "BBG",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/BBG.svg" */"./1867/BBG"))
  },
  "1867/BO": {
    name: "BO",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/BO.svg" */"./1867/BO"))
  },
  "1867/CA": {
    name: "CA",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/CA.svg" */"./1867/CA"))
  },
  "1867/CN": {
    name: "CN",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/CN.svg" */"./1867/CN"))
  },
  "1867/CNR": {
    name: "CNR",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/CNR.svg" */"./1867/CNR"))
  },
  "1867/CO": {
    name: "CO",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/CO.svg" */"./1867/CO"))
  },
  "1867/CPR": {
    name: "CPR",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/CPR.svg" */"./1867/CPR"))
  },
  "1867/CS": {
    name: "CS",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/CS.svg" */"./1867/CS"))
  },
  "1867/CV": {
    name: "CV",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/CV.svg" */"./1867/CV"))
  },
  "1867/GTR": {
    name: "GTR",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/GTR.svg" */"./1867/GTR"))
  },
  "1867/GWR": {
    name: "GWR",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/GWR.svg" */"./1867/GWR"))
  },
  "1867/ICR": {
    name: "ICR",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/ICR.svg" */"./1867/ICR"))
  },
  "1867/KP": {
    name: "KP",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/KP.svg" */"./1867/KP"))
  },
  "1867/LPS": {
    name: "LPS",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/LPS.svg" */"./1867/LPS"))
  },
  "1867/NO": {
    name: "NO",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/NO.svg" */"./1867/NO"))
  },
  "1867/NTR": {
    name: "NTR",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/NTR.svg" */"./1867/NTR"))
  },
  "1867/NYC": {
    name: "NYC",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/NYC.svg" */"./1867/NYC"))
  },
  "1867/OP": {
    name: "OP",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/OP.svg" */"./1867/OP"))
  },
  "1867/PM": {
    name: "PM",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/PM.svg" */"./1867/PM"))
  },
  "1867/QLS": {
    name: "QLS",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/QLS.svg" */"./1867/QLS"))
  },
  "1867/SLA": {
    name: "SLA",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/SLA.svg" */"./1867/SLA"))
  },
  "1867/TGB": {
    name: "TGB",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/TGB.svg" */"./1867/TGB"))
  },
  "1867/THB": {
    name: "THB",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/THB.svg" */"./1867/THB"))
  },
  "1867/TN": {
    name: "TN",
    group: "1867",
    Component: lazy(() => import(/* webpackChunkName: "1867/TN.svg" */"./1867/TN"))
  },
  "1870/ATSF": {
    name: "ATSF",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/ATSF.svg" */"./1870/ATSF"))
  },
  "1870/CBQ": {
    name: "CBQ",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/CBQ.svg" */"./1870/CBQ"))
  },
  "1870/FW": {
    name: "FW",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/FW.svg" */"./1870/FW"))
  },
  "1870/GMO": {
    name: "GMO",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/GMO.svg" */"./1870/GMO"))
  },
  "1870/IC": {
    name: "IC",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/IC.svg" */"./1870/IC"))
  },
  "1870/MKT": {
    name: "MKT",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/MKT.svg" */"./1870/MKT"))
  },
  "1870/MOPAC_B": {
    name: "MOPAC_B",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/MOPAC_B.svg" */"./1870/MOPAC_B"))
  },
  "1870/MP": {
    name: "MP",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/MP.svg" */"./1870/MP"))
  },
  "1870/SLSF": {
    name: "SLSF",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/SLSF.svg" */"./1870/SLSF"))
  },
  "1870/SP": {
    name: "SP",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/SP.svg" */"./1870/SP"))
  },
  "1870/SR": {
    name: "SR",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/SR.svg" */"./1870/SR"))
  },
  "1870/SSW": {
    name: "SSW",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/SSW.svg" */"./1870/SSW"))
  },
  "1870/TP": {
    name: "TP",
    group: "1870",
    Component: lazy(() => import(/* webpackChunkName: "1870/TP.svg" */"./1870/TP"))
  },
  "1871BC/CNOR": {
    name: "CNOR",
    group: "1871BC",
    Component: lazy(() => import(/* webpackChunkName: "1871BC/CNOR.svg" */"./1871BC/CNOR"))
  },
  "1871BC/CPR": {
    name: "CPR",
    group: "1871BC",
    Component: lazy(() => import(/* webpackChunkName: "1871BC/CPR.svg" */"./1871BC/CPR"))
  },
  "1871BC/GTP": {
    name: "GTP",
    group: "1871BC",
    Component: lazy(() => import(/* webpackChunkName: "1871BC/GTP.svg" */"./1871BC/GTP"))
  },
  "1871BC/NORPAC": {
    name: "NORPAC",
    group: "1871BC",
    Component: lazy(() => import(/* webpackChunkName: "1871BC/NORPAC.svg" */"./1871BC/NORPAC"))
  },
  "1871BC/PGER": {
    name: "PGER",
    group: "1871BC",
    Component: lazy(() => import(/* webpackChunkName: "1871BC/PGER.svg" */"./1871BC/PGER"))
  },
  "1889/AR": {
    name: "AR",
    group: "1889",
    Component: lazy(() => import(/* webpackChunkName: "1889/AR.svg" */"./1889/AR"))
  },
  "1889/IR": {
    name: "IR",
    group: "1889",
    Component: lazy(() => import(/* webpackChunkName: "1889/IR.svg" */"./1889/IR"))
  },
  "1889/KO": {
    name: "KO",
    group: "1889",
    Component: lazy(() => import(/* webpackChunkName: "1889/KO.svg" */"./1889/KO"))
  },
  "1889/KU": {
    name: "KU",
    group: "1889",
    Component: lazy(() => import(/* webpackChunkName: "1889/KU.svg" */"./1889/KU"))
  },
  "1889/SR": {
    name: "SR",
    group: "1889",
    Component: lazy(() => import(/* webpackChunkName: "1889/SR.svg" */"./1889/SR"))
  },
  "1889/TR": {
    name: "TR",
    group: "1889",
    Component: lazy(() => import(/* webpackChunkName: "1889/TR.svg" */"./1889/TR"))
  },
  "1889/UR": {
    name: "UR",
    group: "1889",
    Component: lazy(() => import(/* webpackChunkName: "1889/UR.svg" */"./1889/UR"))
  },
  "18Chesapeake/BO": {
    name: "BO",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/BO.svg" */"./18Chesapeake/BO"))
  },
  "18Chesapeake/CA": {
    name: "CA",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/CA.svg" */"./18Chesapeake/CA"))
  },
  "18Chesapeake/CO": {
    name: "CO",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/CO.svg" */"./18Chesapeake/CO"))
  },
  "18Chesapeake/LV": {
    name: "LV",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/LV.svg" */"./18Chesapeake/LV"))
  },
  "18Chesapeake/NW": {
    name: "NW",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/NW.svg" */"./18Chesapeake/NW"))
  },
  "18Chesapeake/PLE": {
    name: "PLE",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/PLE.svg" */"./18Chesapeake/PLE"))
  },
  "18Chesapeake/PRR": {
    name: "PRR",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/PRR.svg" */"./18Chesapeake/PRR"))
  },
  "18Chesapeake/SRR": {
    name: "SRR",
    group: "18Chesapeake",
    Component: lazy(() => import(/* webpackChunkName: "18Chesapeake/SRR.svg" */"./18Chesapeake/SRR"))
  },
  "18EE/BDZ": {
    name: "BDZ",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/BDZ.svg" */"./18EE/BDZ"))
  },
  "18EE/CFM": {
    name: "CFM",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/CFM.svg" */"./18EE/CFM"))
  },
  "18EE/CFR": {
    name: "CFR",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/CFR.svg" */"./18EE/CFR"))
  },
  "18EE/CO": {
    name: "CO",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/CO.svg" */"./18EE/CO"))
  },
  "18EE/HZ": {
    name: "HZ",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/HZ.svg" */"./18EE/HZ"))
  },
  "18EE/KFNB": {
    name: "KFNB",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/KFNB.svg" */"./18EE/KFNB"))
  },
  "18EE/KSS": {
    name: "KSS",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/KSS.svg" */"./18EE/KSS"))
  },
  "18EE/LG": {
    name: "LG",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/LG.svg" */"./18EE/LG"))
  },
  "18EE/LRZD": {
    name: "LRZD",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/LRZD.svg" */"./18EE/LRZD"))
  },
  "18EE/LV": {
    name: "LV",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/LV.svg" */"./18EE/LV"))
  },
  "18EE/MAV": {
    name: "MAV",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/MAV.svg" */"./18EE/MAV"))
  },
  "18EE/MKV": {
    name: "MKV",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/MKV.svg" */"./18EE/MKV"))
  },
  "18EE/OKE": {
    name: "OKE",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/OKE.svg" */"./18EE/OKE"))
  },
  "18EE/OSE": {
    name: "OSE",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/OSE.svg" */"./18EE/OSE"))
  },
  "18EE/POB": {
    name: "POB",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/POB.svg" */"./18EE/POB"))
  },
  "18EE/PZZ": {
    name: "PZZ",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/PZZ.svg" */"./18EE/PZZ"))
  },
  "18EE/SB": {
    name: "SB",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/SB.svg" */"./18EE/SB"))
  },
  "18EE/SPWR": {
    name: "SPWR",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/SPWR.svg" */"./18EE/SPWR"))
  },
  "18EE/SWPR": {
    name: "SWPR",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/SWPR.svg" */"./18EE/SWPR"))
  },
  "18EE/TCDD": {
    name: "TCDD",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/TCDD.svg" */"./18EE/TCDD"))
  },
  "18EE/WW": {
    name: "WW",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/WW.svg" */"./18EE/WW"))
  },
  "18EE/ZS": {
    name: "ZS",
    group: "18EE",
    Component: lazy(() => import(/* webpackChunkName: "18EE/ZS.svg" */"./18EE/ZS"))
  },
  "18LA/GT": {
    name: "GT",
    group: "18LA",
    Component: lazy(() => import(/* webpackChunkName: "18LA/GT.svg" */"./18LA/GT"))
  },
  "18LA/OCR": {
    name: "OCR",
    group: "18LA",
    Component: lazy(() => import(/* webpackChunkName: "18LA/OCR.svg" */"./18LA/OCR"))
  },
  "18Mex/CHI": {
    name: "CHI",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/CHI.svg" */"./18Mex/CHI"))
  },
  "18Mex/MC": {
    name: "MC",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/MC.svg" */"./18Mex/MC"))
  },
  "18Mex/MEX": {
    name: "MEX",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/MEX.svg" */"./18Mex/MEX"))
  },
  "18Mex/NdM": {
    name: "NdM",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/NdM.svg" */"./18Mex/NdM"))
  },
  "18Mex/PAC": {
    name: "PAC",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/PAC.svg" */"./18Mex/PAC"))
  },
  "18Mex/SP": {
    name: "SP",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/SP.svg" */"./18Mex/SP"))
  },
  "18Mex/TM": {
    name: "TM",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/TM.svg" */"./18Mex/TM"))
  },
  "18Mex/UdY": {
    name: "UdY",
    group: "18Mex",
    Component: lazy(() => import(/* webpackChunkName: "18Mex/UdY.svg" */"./18Mex/UdY"))
  },
  "18MI/AA": {
    name: "AA",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/AA.svg" */"./18MI/AA"))
  },
  "18MI/DM": {
    name: "DM",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/DM.svg" */"./18MI/DM"))
  },
  "18MI/DTI": {
    name: "DTI",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/DTI.svg" */"./18MI/DTI"))
  },
  "18MI/GRI": {
    name: "GRI",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/GRI.svg" */"./18MI/GRI"))
  },
  "18MI/GT2": {
    name: "GT2",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/GT2.svg" */"./18MI/GT2"))
  },
  "18MI/LSMS": {
    name: "LSMS",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/LSMS.svg" */"./18MI/LSMS"))
  },
  "18MI/MiC": {
    name: "MiC",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/MiC.svg" */"./18MI/MiC"))
  },
  "18MI/PM": {
    name: "PM",
    group: "18MI",
    Component: lazy(() => import(/* webpackChunkName: "18MI/PM.svg" */"./18MI/PM"))
  },
  "18OC/ATSF": {
    name: "ATSF",
    group: "18OC",
    Component: lazy(() => import(/* webpackChunkName: "18OC/ATSF.svg" */"./18OC/ATSF"))
  },
  "18OC/DRR": {
    name: "DRR",
    group: "18OC",
    Component: lazy(() => import(/* webpackChunkName: "18OC/DRR.svg" */"./18OC/DRR"))
  },
  "18OC/PE": {
    name: "PE",
    group: "18OC",
    Component: lazy(() => import(/* webpackChunkName: "18OC/PE.svg" */"./18OC/PE"))
  },
  "18OC/SP": {
    name: "SP",
    group: "18OC",
    Component: lazy(() => import(/* webpackChunkName: "18OC/SP.svg" */"./18OC/SP"))
  },
  "18OC/SPSF": {
    name: "SPSF",
    group: "18OC",
    Component: lazy(() => import(/* webpackChunkName: "18OC/SPSF.svg" */"./18OC/SPSF"))
  },
  "18Philadelphia/ERIEgreen": {
    name: "ERIEgreen",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/ERIEgreen.svg" */"./18Philadelphia/ERIEgreen"))
  },
  "18Philadelphia/Lackawanna": {
    name: "Lackawanna",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/Lackawanna.svg" */"./18Philadelphia/Lackawanna"))
  },
  "18Philadelphia/LVRR": {
    name: "LVRR",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/LVRR.svg" */"./18Philadelphia/LVRR"))
  },
  "18Philadelphia/NJC": {
    name: "NJC",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/NJC.svg" */"./18Philadelphia/NJC"))
  },
  "18Philadelphia/NYCblack": {
    name: "NYCblack",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/NYCblack.svg" */"./18Philadelphia/NYCblack"))
  },
  "18Philadelphia/NYOW": {
    name: "NYOW",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/NYOW.svg" */"./18Philadelphia/NYOW"))
  },
  "18Philadelphia/NYSW": {
    name: "NYSW",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/NYSW.svg" */"./18Philadelphia/NYSW"))
  },
  "18Philadelphia/PRRred": {
    name: "PRRred",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/PRRred.svg" */"./18Philadelphia/PRRred"))
  },
  "18Philadelphia/ReadingBW": {
    name: "ReadingBW",
    group: "18Philadelphia",
    Component: lazy(() => import(/* webpackChunkName: "18Philadelphia/ReadingBW.svg" */"./18Philadelphia/ReadingBW"))
  },
  "18TraXX2020/APRR": {
    name: "APRR",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/APRR.svg" */"./18TraXX2020/APRR"))
  },
  "18TraXX2020/ATSF": {
    name: "ATSF",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/ATSF.svg" */"./18TraXX2020/ATSF"))
  },
  "18TraXX2020/CBQ": {
    name: "CBQ",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/CBQ.svg" */"./18TraXX2020/CBQ"))
  },
  "18TraXX2020/CENTPAC": {
    name: "CENTPAC",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/CENTPAC.svg" */"./18TraXX2020/CENTPAC"))
  },
  "18TraXX2020/COSO": {
    name: "COSO",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/COSO.svg" */"./18TraXX2020/COSO"))
  },
  "18TraXX2020/KCS": {
    name: "KCS",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/KCS.svg" */"./18TraXX2020/KCS"))
  },
  "18TraXX2020/MOPAC_B": {
    name: "MOPAC_B",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/MOPAC_B.svg" */"./18TraXX2020/MOPAC_B"))
  },
  "18TraXX2020/NORPAC": {
    name: "NORPAC",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/NORPAC.svg" */"./18TraXX2020/NORPAC"))
  },
  "18TraXX2020/TraXX": {
    name: "TraXX",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/TraXX.svg" */"./18TraXX2020/TraXX"))
  },
  "18TraXX2020/UP": {
    name: "UP",
    group: "18TraXX2020",
    Component: lazy(() => import(/* webpackChunkName: "18TraXX2020/UP.svg" */"./18TraXX2020/UP"))
  },
  "18WE/B": {
    name: "B",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/B.svg" */"./18WE/B"))
  },
  "18WE/BHB": {
    name: "BHB",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/BHB.svg" */"./18WE/BHB"))
  },
  "18WE/DSJ": {
    name: "DSJ",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/DSJ.svg" */"./18WE/DSJ"))
  },
  "18WE/EST": {
    name: "EST",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/EST.svg" */"./18WE/EST"))
  },
  "18WE/GC": {
    name: "GC",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/GC.svg" */"./18WE/GC"))
  },
  "18WE/HSM": {
    name: "HSM",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/HSM.svg" */"./18WE/HSM"))
  },
  "18WE/KFNB": {
    name: "KFNB",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/KFNB.svg" */"./18WE/KFNB"))
  },
  "18WE/KSS": {
    name: "KSS",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/KSS.svg" */"./18WE/KSS"))
  },
  "18WE/LV": {
    name: "LV",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/LV.svg" */"./18WE/LV"))
  },
  "18WE/MAV": {
    name: "MAV",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/MAV.svg" */"./18WE/MAV"))
  },
  "18WE/MIDI": {
    name: "MIDI",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/MIDI.svg" */"./18WE/MIDI"))
  },
  "18WE/NORD": {
    name: "NORD",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/NORD.svg" */"./18WE/NORD"))
  },
  "18WE/Ouest": {
    name: "Ouest",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/Ouest.svg" */"./18WE/Ouest"))
  },
  "18WE/PLM": {
    name: "PLM",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/PLM.svg" */"./18WE/PLM"))
  },
  "18WE/PO": {
    name: "PO",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/PO.svg" */"./18WE/PO"))
  },
  "18WE/POB": {
    name: "POB",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/POB.svg" */"./18WE/POB"))
  },
  "18WE/RhE": {
    name: "RhE",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/RhE.svg" */"./18WE/RhE"))
  },
  "18WE/SB": {
    name: "SB",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/SB.svg" */"./18WE/SB"))
  },
  "18WE/SFAI": {
    name: "SFAI",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/SFAI.svg" */"./18WE/SFAI"))
  },
  "18WE/SFR": {
    name: "SFR",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/SFR.svg" */"./18WE/SFR"))
  },
  "18WE/WW": {
    name: "WW",
    group: "18WE",
    Component: lazy(() => import(/* webpackChunkName: "18WE/WW.svg" */"./18WE/WW"))
  },
  "BDW": {
    name: "BDW",
    Component: lazy(() => import(/* webpackChunkName: "BDW.svg" */"././BDW"))
  },
  "BLW": {
    name: "BLW",
    Component: lazy(() => import(/* webpackChunkName: "BLW.svg" */"././BLW"))
  },
  "BMLR": {
    name: "BMLR",
    Component: lazy(() => import(/* webpackChunkName: "BMLR.svg" */"././BMLR"))
  },
  "countries/ad": {
    name: "ad",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ad.svg" */"./countries/ad"))
  },
  "countries/ae": {
    name: "ae",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ae.svg" */"./countries/ae"))
  },
  "countries/af": {
    name: "af",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/af.svg" */"./countries/af"))
  },
  "countries/ag": {
    name: "ag",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ag.svg" */"./countries/ag"))
  },
  "countries/ai": {
    name: "ai",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ai.svg" */"./countries/ai"))
  },
  "countries/al": {
    name: "al",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/al.svg" */"./countries/al"))
  },
  "countries/am": {
    name: "am",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/am.svg" */"./countries/am"))
  },
  "countries/an": {
    name: "an",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/an.svg" */"./countries/an"))
  },
  "countries/ao": {
    name: "ao",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ao.svg" */"./countries/ao"))
  },
  "countries/aq": {
    name: "aq",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/aq.svg" */"./countries/aq"))
  },
  "countries/ar": {
    name: "ar",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ar.svg" */"./countries/ar"))
  },
  "countries/as": {
    name: "as",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/as.svg" */"./countries/as"))
  },
  "countries/at": {
    name: "at",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/at.svg" */"./countries/at"))
  },
  "countries/au": {
    name: "au",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/au.svg" */"./countries/au"))
  },
  "countries/aw": {
    name: "aw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/aw.svg" */"./countries/aw"))
  },
  "countries/ax": {
    name: "ax",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ax.svg" */"./countries/ax"))
  },
  "countries/az": {
    name: "az",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/az.svg" */"./countries/az"))
  },
  "countries/ba": {
    name: "ba",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ba.svg" */"./countries/ba"))
  },
  "countries/bb": {
    name: "bb",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bb.svg" */"./countries/bb"))
  },
  "countries/bd": {
    name: "bd",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bd.svg" */"./countries/bd"))
  },
  "countries/be": {
    name: "be",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/be.svg" */"./countries/be"))
  },
  "countries/bf": {
    name: "bf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bf.svg" */"./countries/bf"))
  },
  "countries/bg": {
    name: "bg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bg.svg" */"./countries/bg"))
  },
  "countries/bh": {
    name: "bh",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bh.svg" */"./countries/bh"))
  },
  "countries/bi": {
    name: "bi",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bi.svg" */"./countries/bi"))
  },
  "countries/bj": {
    name: "bj",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bj.svg" */"./countries/bj"))
  },
  "countries/bl": {
    name: "bl",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bl.svg" */"./countries/bl"))
  },
  "countries/bm": {
    name: "bm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bm.svg" */"./countries/bm"))
  },
  "countries/bn": {
    name: "bn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bn.svg" */"./countries/bn"))
  },
  "countries/bo": {
    name: "bo",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bo.svg" */"./countries/bo"))
  },
  "countries/bq": {
    name: "bq",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bq.svg" */"./countries/bq"))
  },
  "countries/br": {
    name: "br",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/br.svg" */"./countries/br"))
  },
  "countries/bs": {
    name: "bs",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bs.svg" */"./countries/bs"))
  },
  "countries/bt": {
    name: "bt",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bt.svg" */"./countries/bt"))
  },
  "countries/bv": {
    name: "bv",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bv.svg" */"./countries/bv"))
  },
  "countries/bw": {
    name: "bw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bw.svg" */"./countries/bw"))
  },
  "countries/by": {
    name: "by",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/by.svg" */"./countries/by"))
  },
  "countries/bz": {
    name: "bz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/bz.svg" */"./countries/bz"))
  },
  "countries/ca": {
    name: "ca",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ca.svg" */"./countries/ca"))
  },
  "countries/cc": {
    name: "cc",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cc.svg" */"./countries/cc"))
  },
  "countries/cd": {
    name: "cd",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cd.svg" */"./countries/cd"))
  },
  "countries/cf": {
    name: "cf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cf.svg" */"./countries/cf"))
  },
  "countries/cg": {
    name: "cg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cg.svg" */"./countries/cg"))
  },
  "countries/ch": {
    name: "ch",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ch.svg" */"./countries/ch"))
  },
  "countries/ci": {
    name: "ci",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ci.svg" */"./countries/ci"))
  },
  "countries/ck": {
    name: "ck",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ck.svg" */"./countries/ck"))
  },
  "countries/cl": {
    name: "cl",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cl.svg" */"./countries/cl"))
  },
  "countries/cm": {
    name: "cm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cm.svg" */"./countries/cm"))
  },
  "countries/cn": {
    name: "cn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cn.svg" */"./countries/cn"))
  },
  "countries/co": {
    name: "co",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/co.svg" */"./countries/co"))
  },
  "countries/cr": {
    name: "cr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cr.svg" */"./countries/cr"))
  },
  "countries/cu": {
    name: "cu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cu.svg" */"./countries/cu"))
  },
  "countries/cv": {
    name: "cv",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cv.svg" */"./countries/cv"))
  },
  "countries/cw": {
    name: "cw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cw.svg" */"./countries/cw"))
  },
  "countries/cx": {
    name: "cx",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cx.svg" */"./countries/cx"))
  },
  "countries/cy": {
    name: "cy",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cy.svg" */"./countries/cy"))
  },
  "countries/cz": {
    name: "cz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/cz.svg" */"./countries/cz"))
  },
  "countries/de": {
    name: "de",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/de.svg" */"./countries/de"))
  },
  "countries/dj": {
    name: "dj",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/dj.svg" */"./countries/dj"))
  },
  "countries/dk": {
    name: "dk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/dk.svg" */"./countries/dk"))
  },
  "countries/dm": {
    name: "dm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/dm.svg" */"./countries/dm"))
  },
  "countries/do": {
    name: "do",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/do.svg" */"./countries/do"))
  },
  "countries/dz": {
    name: "dz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/dz.svg" */"./countries/dz"))
  },
  "countries/ec": {
    name: "ec",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ec.svg" */"./countries/ec"))
  },
  "countries/ee": {
    name: "ee",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ee.svg" */"./countries/ee"))
  },
  "countries/eg": {
    name: "eg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/eg.svg" */"./countries/eg"))
  },
  "countries/eh": {
    name: "eh",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/eh.svg" */"./countries/eh"))
  },
  "countries/er": {
    name: "er",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/er.svg" */"./countries/er"))
  },
  "countries/es": {
    name: "es",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/es.svg" */"./countries/es"))
  },
  "countries/et": {
    name: "et",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/et.svg" */"./countries/et"))
  },
  "countries/eu": {
    name: "eu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/eu.svg" */"./countries/eu"))
  },
  "countries/fi": {
    name: "fi",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/fi.svg" */"./countries/fi"))
  },
  "countries/fj": {
    name: "fj",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/fj.svg" */"./countries/fj"))
  },
  "countries/fk": {
    name: "fk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/fk.svg" */"./countries/fk"))
  },
  "countries/fm": {
    name: "fm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/fm.svg" */"./countries/fm"))
  },
  "countries/fo": {
    name: "fo",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/fo.svg" */"./countries/fo"))
  },
  "countries/fr": {
    name: "fr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/fr.svg" */"./countries/fr"))
  },
  "countries/ga": {
    name: "ga",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ga.svg" */"./countries/ga"))
  },
  "countries/gb_eng": {
    name: "gb_eng",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gb_eng.svg" */"./countries/gb_eng"))
  },
  "countries/gb_nir": {
    name: "gb_nir",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gb_nir.svg" */"./countries/gb_nir"))
  },
  "countries/gb_sct": {
    name: "gb_sct",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gb_sct.svg" */"./countries/gb_sct"))
  },
  "countries/gb_wls": {
    name: "gb_wls",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gb_wls.svg" */"./countries/gb_wls"))
  },
  "countries/gb": {
    name: "gb",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gb.svg" */"./countries/gb"))
  },
  "countries/gd": {
    name: "gd",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gd.svg" */"./countries/gd"))
  },
  "countries/ge": {
    name: "ge",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ge.svg" */"./countries/ge"))
  },
  "countries/gf": {
    name: "gf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gf.svg" */"./countries/gf"))
  },
  "countries/gg": {
    name: "gg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gg.svg" */"./countries/gg"))
  },
  "countries/gh": {
    name: "gh",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gh.svg" */"./countries/gh"))
  },
  "countries/gi": {
    name: "gi",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gi.svg" */"./countries/gi"))
  },
  "countries/gl": {
    name: "gl",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gl.svg" */"./countries/gl"))
  },
  "countries/gm": {
    name: "gm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gm.svg" */"./countries/gm"))
  },
  "countries/gn": {
    name: "gn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gn.svg" */"./countries/gn"))
  },
  "countries/gp": {
    name: "gp",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gp.svg" */"./countries/gp"))
  },
  "countries/gq": {
    name: "gq",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gq.svg" */"./countries/gq"))
  },
  "countries/gr": {
    name: "gr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gr.svg" */"./countries/gr"))
  },
  "countries/gs": {
    name: "gs",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gs.svg" */"./countries/gs"))
  },
  "countries/gt": {
    name: "gt",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gt.svg" */"./countries/gt"))
  },
  "countries/gu": {
    name: "gu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gu.svg" */"./countries/gu"))
  },
  "countries/gw": {
    name: "gw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gw.svg" */"./countries/gw"))
  },
  "countries/gy": {
    name: "gy",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/gy.svg" */"./countries/gy"))
  },
  "countries/hk": {
    name: "hk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/hk.svg" */"./countries/hk"))
  },
  "countries/hm": {
    name: "hm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/hm.svg" */"./countries/hm"))
  },
  "countries/hn": {
    name: "hn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/hn.svg" */"./countries/hn"))
  },
  "countries/hr": {
    name: "hr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/hr.svg" */"./countries/hr"))
  },
  "countries/ht": {
    name: "ht",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ht.svg" */"./countries/ht"))
  },
  "countries/hu": {
    name: "hu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/hu.svg" */"./countries/hu"))
  },
  "countries/id": {
    name: "id",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/id.svg" */"./countries/id"))
  },
  "countries/ie": {
    name: "ie",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ie.svg" */"./countries/ie"))
  },
  "countries/il": {
    name: "il",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/il.svg" */"./countries/il"))
  },
  "countries/im": {
    name: "im",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/im.svg" */"./countries/im"))
  },
  "countries/in": {
    name: "in",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/in.svg" */"./countries/in"))
  },
  "countries/io": {
    name: "io",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/io.svg" */"./countries/io"))
  },
  "countries/iq": {
    name: "iq",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/iq.svg" */"./countries/iq"))
  },
  "countries/ir": {
    name: "ir",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ir.svg" */"./countries/ir"))
  },
  "countries/is": {
    name: "is",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/is.svg" */"./countries/is"))
  },
  "countries/it": {
    name: "it",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/it.svg" */"./countries/it"))
  },
  "countries/je": {
    name: "je",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/je.svg" */"./countries/je"))
  },
  "countries/jm": {
    name: "jm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/jm.svg" */"./countries/jm"))
  },
  "countries/jo": {
    name: "jo",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/jo.svg" */"./countries/jo"))
  },
  "countries/jp": {
    name: "jp",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/jp.svg" */"./countries/jp"))
  },
  "countries/ke": {
    name: "ke",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ke.svg" */"./countries/ke"))
  },
  "countries/kg": {
    name: "kg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/kg.svg" */"./countries/kg"))
  },
  "countries/kh": {
    name: "kh",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/kh.svg" */"./countries/kh"))
  },
  "countries/ki": {
    name: "ki",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ki.svg" */"./countries/ki"))
  },
  "countries/km": {
    name: "km",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/km.svg" */"./countries/km"))
  },
  "countries/kn": {
    name: "kn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/kn.svg" */"./countries/kn"))
  },
  "countries/kp": {
    name: "kp",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/kp.svg" */"./countries/kp"))
  },
  "countries/kr": {
    name: "kr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/kr.svg" */"./countries/kr"))
  },
  "countries/kw": {
    name: "kw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/kw.svg" */"./countries/kw"))
  },
  "countries/ky": {
    name: "ky",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ky.svg" */"./countries/ky"))
  },
  "countries/kz": {
    name: "kz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/kz.svg" */"./countries/kz"))
  },
  "countries/la": {
    name: "la",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/la.svg" */"./countries/la"))
  },
  "countries/lb": {
    name: "lb",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/lb.svg" */"./countries/lb"))
  },
  "countries/lc": {
    name: "lc",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/lc.svg" */"./countries/lc"))
  },
  "countries/li": {
    name: "li",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/li.svg" */"./countries/li"))
  },
  "countries/lk": {
    name: "lk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/lk.svg" */"./countries/lk"))
  },
  "countries/lr": {
    name: "lr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/lr.svg" */"./countries/lr"))
  },
  "countries/ls": {
    name: "ls",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ls.svg" */"./countries/ls"))
  },
  "countries/lt": {
    name: "lt",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/lt.svg" */"./countries/lt"))
  },
  "countries/lu": {
    name: "lu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/lu.svg" */"./countries/lu"))
  },
  "countries/lv": {
    name: "lv",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/lv.svg" */"./countries/lv"))
  },
  "countries/ly": {
    name: "ly",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ly.svg" */"./countries/ly"))
  },
  "countries/ma": {
    name: "ma",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ma.svg" */"./countries/ma"))
  },
  "countries/mc": {
    name: "mc",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mc.svg" */"./countries/mc"))
  },
  "countries/md": {
    name: "md",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/md.svg" */"./countries/md"))
  },
  "countries/me": {
    name: "me",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/me.svg" */"./countries/me"))
  },
  "countries/mf": {
    name: "mf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mf.svg" */"./countries/mf"))
  },
  "countries/mg": {
    name: "mg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mg.svg" */"./countries/mg"))
  },
  "countries/mh": {
    name: "mh",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mh.svg" */"./countries/mh"))
  },
  "countries/mk": {
    name: "mk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mk.svg" */"./countries/mk"))
  },
  "countries/ml": {
    name: "ml",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ml.svg" */"./countries/ml"))
  },
  "countries/mm": {
    name: "mm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mm.svg" */"./countries/mm"))
  },
  "countries/mn": {
    name: "mn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mn.svg" */"./countries/mn"))
  },
  "countries/mo": {
    name: "mo",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mo.svg" */"./countries/mo"))
  },
  "countries/mp": {
    name: "mp",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mp.svg" */"./countries/mp"))
  },
  "countries/mq": {
    name: "mq",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mq.svg" */"./countries/mq"))
  },
  "countries/mr": {
    name: "mr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mr.svg" */"./countries/mr"))
  },
  "countries/ms": {
    name: "ms",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ms.svg" */"./countries/ms"))
  },
  "countries/mt": {
    name: "mt",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mt.svg" */"./countries/mt"))
  },
  "countries/mu": {
    name: "mu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mu.svg" */"./countries/mu"))
  },
  "countries/mv": {
    name: "mv",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mv.svg" */"./countries/mv"))
  },
  "countries/mw": {
    name: "mw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mw.svg" */"./countries/mw"))
  },
  "countries/mx": {
    name: "mx",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mx.svg" */"./countries/mx"))
  },
  "countries/my": {
    name: "my",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/my.svg" */"./countries/my"))
  },
  "countries/mz": {
    name: "mz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/mz.svg" */"./countries/mz"))
  },
  "countries/na": {
    name: "na",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/na.svg" */"./countries/na"))
  },
  "countries/nc": {
    name: "nc",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/nc.svg" */"./countries/nc"))
  },
  "countries/ne": {
    name: "ne",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ne.svg" */"./countries/ne"))
  },
  "countries/nf": {
    name: "nf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/nf.svg" */"./countries/nf"))
  },
  "countries/ng": {
    name: "ng",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ng.svg" */"./countries/ng"))
  },
  "countries/ni": {
    name: "ni",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ni.svg" */"./countries/ni"))
  },
  "countries/nl": {
    name: "nl",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/nl.svg" */"./countries/nl"))
  },
  "countries/no": {
    name: "no",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/no.svg" */"./countries/no"))
  },
  "countries/np": {
    name: "np",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/np.svg" */"./countries/np"))
  },
  "countries/nr": {
    name: "nr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/nr.svg" */"./countries/nr"))
  },
  "countries/nu": {
    name: "nu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/nu.svg" */"./countries/nu"))
  },
  "countries/nz": {
    name: "nz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/nz.svg" */"./countries/nz"))
  },
  "countries/om": {
    name: "om",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/om.svg" */"./countries/om"))
  },
  "countries/pa": {
    name: "pa",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pa.svg" */"./countries/pa"))
  },
  "countries/pe": {
    name: "pe",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pe.svg" */"./countries/pe"))
  },
  "countries/pf": {
    name: "pf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pf.svg" */"./countries/pf"))
  },
  "countries/pg": {
    name: "pg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pg.svg" */"./countries/pg"))
  },
  "countries/ph": {
    name: "ph",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ph.svg" */"./countries/ph"))
  },
  "countries/pk": {
    name: "pk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pk.svg" */"./countries/pk"))
  },
  "countries/pl": {
    name: "pl",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pl.svg" */"./countries/pl"))
  },
  "countries/pm": {
    name: "pm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pm.svg" */"./countries/pm"))
  },
  "countries/pn": {
    name: "pn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pn.svg" */"./countries/pn"))
  },
  "countries/pr": {
    name: "pr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pr.svg" */"./countries/pr"))
  },
  "countries/ps": {
    name: "ps",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ps.svg" */"./countries/ps"))
  },
  "countries/pt": {
    name: "pt",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pt.svg" */"./countries/pt"))
  },
  "countries/pw": {
    name: "pw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/pw.svg" */"./countries/pw"))
  },
  "countries/py": {
    name: "py",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/py.svg" */"./countries/py"))
  },
  "countries/qa": {
    name: "qa",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/qa.svg" */"./countries/qa"))
  },
  "countries/re": {
    name: "re",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/re.svg" */"./countries/re"))
  },
  "countries/ro": {
    name: "ro",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ro.svg" */"./countries/ro"))
  },
  "countries/rs": {
    name: "rs",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/rs.svg" */"./countries/rs"))
  },
  "countries/ru": {
    name: "ru",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ru.svg" */"./countries/ru"))
  },
  "countries/rw": {
    name: "rw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/rw.svg" */"./countries/rw"))
  },
  "countries/sa": {
    name: "sa",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sa.svg" */"./countries/sa"))
  },
  "countries/sb": {
    name: "sb",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sb.svg" */"./countries/sb"))
  },
  "countries/sc": {
    name: "sc",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sc.svg" */"./countries/sc"))
  },
  "countries/sd": {
    name: "sd",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sd.svg" */"./countries/sd"))
  },
  "countries/se": {
    name: "se",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/se.svg" */"./countries/se"))
  },
  "countries/sg": {
    name: "sg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sg.svg" */"./countries/sg"))
  },
  "countries/sh": {
    name: "sh",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sh.svg" */"./countries/sh"))
  },
  "countries/si": {
    name: "si",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/si.svg" */"./countries/si"))
  },
  "countries/sj": {
    name: "sj",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sj.svg" */"./countries/sj"))
  },
  "countries/sk": {
    name: "sk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sk.svg" */"./countries/sk"))
  },
  "countries/sl": {
    name: "sl",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sl.svg" */"./countries/sl"))
  },
  "countries/sm": {
    name: "sm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sm.svg" */"./countries/sm"))
  },
  "countries/sn": {
    name: "sn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sn.svg" */"./countries/sn"))
  },
  "countries/so": {
    name: "so",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/so.svg" */"./countries/so"))
  },
  "countries/sr": {
    name: "sr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sr.svg" */"./countries/sr"))
  },
  "countries/ss": {
    name: "ss",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ss.svg" */"./countries/ss"))
  },
  "countries/st": {
    name: "st",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/st.svg" */"./countries/st"))
  },
  "countries/sv": {
    name: "sv",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sv.svg" */"./countries/sv"))
  },
  "countries/sx": {
    name: "sx",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sx.svg" */"./countries/sx"))
  },
  "countries/sy": {
    name: "sy",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sy.svg" */"./countries/sy"))
  },
  "countries/sz": {
    name: "sz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/sz.svg" */"./countries/sz"))
  },
  "countries/tc": {
    name: "tc",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tc.svg" */"./countries/tc"))
  },
  "countries/td": {
    name: "td",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/td.svg" */"./countries/td"))
  },
  "countries/tf": {
    name: "tf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tf.svg" */"./countries/tf"))
  },
  "countries/tg": {
    name: "tg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tg.svg" */"./countries/tg"))
  },
  "countries/th": {
    name: "th",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/th.svg" */"./countries/th"))
  },
  "countries/tj": {
    name: "tj",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tj.svg" */"./countries/tj"))
  },
  "countries/tk": {
    name: "tk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tk.svg" */"./countries/tk"))
  },
  "countries/tl": {
    name: "tl",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tl.svg" */"./countries/tl"))
  },
  "countries/tm": {
    name: "tm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tm.svg" */"./countries/tm"))
  },
  "countries/tn": {
    name: "tn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tn.svg" */"./countries/tn"))
  },
  "countries/to": {
    name: "to",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/to.svg" */"./countries/to"))
  },
  "countries/tr": {
    name: "tr",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tr.svg" */"./countries/tr"))
  },
  "countries/tt": {
    name: "tt",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tt.svg" */"./countries/tt"))
  },
  "countries/tv": {
    name: "tv",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tv.svg" */"./countries/tv"))
  },
  "countries/tw": {
    name: "tw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tw.svg" */"./countries/tw"))
  },
  "countries/tz": {
    name: "tz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/tz.svg" */"./countries/tz"))
  },
  "countries/ua": {
    name: "ua",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ua.svg" */"./countries/ua"))
  },
  "countries/ug": {
    name: "ug",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ug.svg" */"./countries/ug"))
  },
  "countries/um": {
    name: "um",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/um.svg" */"./countries/um"))
  },
  "countries/us": {
    name: "us",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/us.svg" */"./countries/us"))
  },
  "countries/uy": {
    name: "uy",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/uy.svg" */"./countries/uy"))
  },
  "countries/uz": {
    name: "uz",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/uz.svg" */"./countries/uz"))
  },
  "countries/va": {
    name: "va",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/va.svg" */"./countries/va"))
  },
  "countries/vc": {
    name: "vc",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/vc.svg" */"./countries/vc"))
  },
  "countries/ve": {
    name: "ve",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ve.svg" */"./countries/ve"))
  },
  "countries/vg": {
    name: "vg",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/vg.svg" */"./countries/vg"))
  },
  "countries/vi": {
    name: "vi",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/vi.svg" */"./countries/vi"))
  },
  "countries/vn": {
    name: "vn",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/vn.svg" */"./countries/vn"))
  },
  "countries/vu": {
    name: "vu",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/vu.svg" */"./countries/vu"))
  },
  "countries/wf": {
    name: "wf",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/wf.svg" */"./countries/wf"))
  },
  "countries/ws": {
    name: "ws",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ws.svg" */"./countries/ws"))
  },
  "countries/xk": {
    name: "xk",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/xk.svg" */"./countries/xk"))
  },
  "countries/ye": {
    name: "ye",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/ye.svg" */"./countries/ye"))
  },
  "countries/yt": {
    name: "yt",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/yt.svg" */"./countries/yt"))
  },
  "countries/za": {
    name: "za",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/za.svg" */"./countries/za"))
  },
  "countries/zm": {
    name: "zm",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/zm.svg" */"./countries/zm"))
  },
  "countries/zw": {
    name: "zw",
    group: "countries",
    Component: lazy(() => import(/* webpackChunkName: "countries/zw.svg" */"./countries/zw"))
  },
  "dev/apple": {
    name: "apple",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/apple.svg" */"./dev/apple"))
  },
  "dev/atom": {
    name: "atom",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/atom.svg" */"./dev/atom"))
  },
  "dev/aws": {
    name: "aws",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/aws.svg" */"./dev/aws"))
  },
  "dev/azure": {
    name: "azure",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/azure.svg" */"./dev/azure"))
  },
  "dev/couchbase": {
    name: "couchbase",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/couchbase.svg" */"./dev/couchbase"))
  },
  "dev/docker": {
    name: "docker",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/docker.svg" */"./dev/docker"))
  },
  "dev/emacs": {
    name: "emacs",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/emacs.svg" */"./dev/emacs"))
  },
  "dev/gcloud": {
    name: "gcloud",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/gcloud.svg" */"./dev/gcloud"))
  },
  "dev/git": {
    name: "git",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/git.svg" */"./dev/git"))
  },
  "dev/github": {
    name: "github",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/github.svg" */"./dev/github"))
  },
  "dev/k8s": {
    name: "k8s",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/k8s.svg" */"./dev/k8s"))
  },
  "dev/linux": {
    name: "linux",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/linux.svg" */"./dev/linux"))
  },
  "dev/microsoft": {
    name: "microsoft",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/microsoft.svg" */"./dev/microsoft"))
  },
  "dev/mongodb": {
    name: "mongodb",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/mongodb.svg" */"./dev/mongodb"))
  },
  "dev/mysql": {
    name: "mysql",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/mysql.svg" */"./dev/mysql"))
  },
  "dev/nginx": {
    name: "nginx",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/nginx.svg" */"./dev/nginx"))
  },
  "dev/postgresql": {
    name: "postgresql",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/postgresql.svg" */"./dev/postgresql"))
  },
  "dev/redis": {
    name: "redis",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/redis.svg" */"./dev/redis"))
  },
  "dev/vim": {
    name: "vim",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/vim.svg" */"./dev/vim"))
  },
  "dev/vscode": {
    name: "vscode",
    group: "dev",
    Component: lazy(() => import(/* webpackChunkName: "dev/vscode.svg" */"./dev/vscode"))
  },
  "DRGW": {
    name: "DRGW",
    Component: lazy(() => import(/* webpackChunkName: "DRGW.svg" */"././DRGW"))
  },
  "GNWR/GNWR_AS": {
    name: "GNWR_AS",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_AS.svg" */"./GNWR/GNWR_AS"))
  },
  "GNWR/GNWR_BP": {
    name: "GNWR_BP",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_BP.svg" */"./GNWR/GNWR_BP"))
  },
  "GNWR/GNWR_GWRS": {
    name: "GNWR_GWRS",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_GWRS.svg" */"./GNWR/GNWR_GWRS"))
  },
  "GNWR/GNWR_IW": {
    name: "GNWR_IW",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_IW.svg" */"./GNWR/GNWR_IW"))
  },
  "GNWR/GNWR_LD": {
    name: "GNWR_LD",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_LD.svg" */"./GNWR/GNWR_LD"))
  },
  "GNWR/GNWR_PW": {
    name: "GNWR_PW",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_PW.svg" */"./GNWR/GNWR_PW"))
  },
  "GNWR/GNWR_RF": {
    name: "GNWR_RF",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_RF.svg" */"./GNWR/GNWR_RF"))
  },
  "GNWR/GNWR_RL": {
    name: "GNWR_RL",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_RL.svg" */"./GNWR/GNWR_RL"))
  },
  "GNWR/GNWR_RS": {
    name: "GNWR_RS",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR_RS.svg" */"./GNWR/GNWR_RS"))
  },
  "GNWR/GNWR": {
    name: "GNWR",
    group: "GNWR",
    Component: lazy(() => import(/* webpackChunkName: "GNWR/GNWR.svg" */"./GNWR/GNWR"))
  },
  "imperialrussia/ImperialEagle": {
    name: "ImperialEagle",
    group: "imperialrussia",
    Component: lazy(() => import(/* webpackChunkName: "imperialrussia/ImperialEagle.svg" */"./imperialrussia/ImperialEagle"))
  },
  "languages/c": {
    name: "c",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/c.svg" */"./languages/c"))
  },
  "languages/clojure": {
    name: "clojure",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/clojure.svg" */"./languages/clojure"))
  },
  "languages/commonlisp": {
    name: "commonlisp",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/commonlisp.svg" */"./languages/commonlisp"))
  },
  "languages/cpp": {
    name: "cpp",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/cpp.svg" */"./languages/cpp"))
  },
  "languages/csharp": {
    name: "csharp",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/csharp.svg" */"./languages/csharp"))
  },
  "languages/dart": {
    name: "dart",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/dart.svg" */"./languages/dart"))
  },
  "languages/erlang": {
    name: "erlang",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/erlang.svg" */"./languages/erlang"))
  },
  "languages/fsharp": {
    name: "fsharp",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/fsharp.svg" */"./languages/fsharp"))
  },
  "languages/go": {
    name: "go",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/go.svg" */"./languages/go"))
  },
  "languages/haskell": {
    name: "haskell",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/haskell.svg" */"./languages/haskell"))
  },
  "languages/java": {
    name: "java",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/java.svg" */"./languages/java"))
  },
  "languages/javascript": {
    name: "javascript",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/javascript.svg" */"./languages/javascript"))
  },
  "languages/lua": {
    name: "lua",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/lua.svg" */"./languages/lua"))
  },
  "languages/perl": {
    name: "perl",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/perl.svg" */"./languages/perl"))
  },
  "languages/php": {
    name: "php",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/php.svg" */"./languages/php"))
  },
  "languages/python": {
    name: "python",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/python.svg" */"./languages/python"))
  },
  "languages/ruby": {
    name: "ruby",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/ruby.svg" */"./languages/ruby"))
  },
  "languages/rust": {
    name: "rust",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/rust.svg" */"./languages/rust"))
  },
  "languages/scala": {
    name: "scala",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/scala.svg" */"./languages/scala"))
  },
  "languages/shell": {
    name: "shell",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/shell.svg" */"./languages/shell"))
  },
  "languages/swift": {
    name: "swift",
    group: "languages",
    Component: lazy(() => import(/* webpackChunkName: "languages/swift.svg" */"./languages/swift"))
  },
  "lotr/BPS": {
    name: "BPS",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/BPS.svg" */"./lotr/BPS"))
  },
  "lotr/DME": {
    name: "DME",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/DME.svg" */"./lotr/DME"))
  },
  "lotr/E": {
    name: "E",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/E.svg" */"./lotr/E"))
  },
  "lotr/FHR": {
    name: "FHR",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/FHR.svg" */"./lotr/FHR"))
  },
  "lotr/LLM": {
    name: "LLM",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/LLM.svg" */"./lotr/LLM"))
  },
  "lotr/MTR": {
    name: "MTR",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/MTR.svg" */"./lotr/MTR"))
  },
  "lotr/O": {
    name: "O",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/O.svg" */"./lotr/O"))
  },
  "lotr/RGH": {
    name: "RGH",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/RGH.svg" */"./lotr/RGH"))
  },
  "lotr/SER": {
    name: "SER",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/SER.svg" */"./lotr/SER"))
  },
  "lotr/SWR": {
    name: "SWR",
    group: "lotr",
    Component: lazy(() => import(/* webpackChunkName: "lotr/SWR.svg" */"./lotr/SWR"))
  },
  "misc/GN": {
    name: "GN",
    group: "misc",
    Component: lazy(() => import(/* webpackChunkName: "misc/GN.svg" */"./misc/GN"))
  },
  "misc/MR": {
    name: "MR",
    group: "misc",
    Component: lazy(() => import(/* webpackChunkName: "misc/MR.svg" */"./misc/MR"))
  },
  "misc/SPS": {
    name: "SPS",
    group: "misc",
    Component: lazy(() => import(/* webpackChunkName: "misc/SPS.svg" */"./misc/SPS"))
  },
  "NFL": {
    name: "NFL",
    Component: lazy(() => import(/* webpackChunkName: "NFL.svg" */"././NFL"))
  },
  "NYSW2": {
    name: "NYSW2",
    Component: lazy(() => import(/* webpackChunkName: "NYSW2.svg" */"././NYSW2"))
  },
  "owl/atlanta_reign": {
    name: "atlanta_reign",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/atlanta_reign.svg" */"./owl/atlanta_reign"))
  },
  "owl/boston_uprising": {
    name: "boston_uprising",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/boston_uprising.svg" */"./owl/boston_uprising"))
  },
  "owl/chengdu_hunters": {
    name: "chengdu_hunters",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/chengdu_hunters.svg" */"./owl/chengdu_hunters"))
  },
  "owl/dallas_fuel": {
    name: "dallas_fuel",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/dallas_fuel.svg" */"./owl/dallas_fuel"))
  },
  "owl/florida_mayhem": {
    name: "florida_mayhem",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/florida_mayhem.svg" */"./owl/florida_mayhem"))
  },
  "owl/guangzhou_charge": {
    name: "guangzhou_charge",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/guangzhou_charge.svg" */"./owl/guangzhou_charge"))
  },
  "owl/hangzhou_spark": {
    name: "hangzhou_spark",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/hangzhou_spark.svg" */"./owl/hangzhou_spark"))
  },
  "owl/houston_outlaws": {
    name: "houston_outlaws",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/houston_outlaws.svg" */"./owl/houston_outlaws"))
  },
  "owl/london_spitfire": {
    name: "london_spitfire",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/london_spitfire.svg" */"./owl/london_spitfire"))
  },
  "owl/los_angeles_gladiators": {
    name: "los_angeles_gladiators",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/los_angeles_gladiators.svg" */"./owl/los_angeles_gladiators"))
  },
  "owl/los_angeles_valiant": {
    name: "los_angeles_valiant",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/los_angeles_valiant.svg" */"./owl/los_angeles_valiant"))
  },
  "owl/new_york_excelsior": {
    name: "new_york_excelsior",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/new_york_excelsior.svg" */"./owl/new_york_excelsior"))
  },
  "owl/paris_eternal": {
    name: "paris_eternal",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/paris_eternal.svg" */"./owl/paris_eternal"))
  },
  "owl/philadelphia_fusion": {
    name: "philadelphia_fusion",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/philadelphia_fusion.svg" */"./owl/philadelphia_fusion"))
  },
  "owl/san_francisco_shock": {
    name: "san_francisco_shock",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/san_francisco_shock.svg" */"./owl/san_francisco_shock"))
  },
  "owl/seoul_dynasty": {
    name: "seoul_dynasty",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/seoul_dynasty.svg" */"./owl/seoul_dynasty"))
  },
  "owl/shanghai_dragons": {
    name: "shanghai_dragons",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/shanghai_dragons.svg" */"./owl/shanghai_dragons"))
  },
  "owl/toronto_defiant": {
    name: "toronto_defiant",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/toronto_defiant.svg" */"./owl/toronto_defiant"))
  },
  "owl/vancouver_titans": {
    name: "vancouver_titans",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/vancouver_titans.svg" */"./owl/vancouver_titans"))
  },
  "owl/washington_justice": {
    name: "washington_justice",
    group: "owl",
    Component: lazy(() => import(/* webpackChunkName: "owl/washington_justice.svg" */"./owl/washington_justice"))
  },
  "PWR": {
    name: "PWR",
    Component: lazy(() => import(/* webpackChunkName: "PWR.svg" */"././PWR"))
  },
  "Reading": {
    name: "Reading",
    Component: lazy(() => import(/* webpackChunkName: "Reading.svg" */"././Reading"))
  },
  "SJ": {
    name: "SJ",
    Component: lazy(() => import(/* webpackChunkName: "SJ.svg" */"././SJ"))
  },
  "System18/DGN": {
    name: "DGN",
    group: "System18",
    Component: lazy(() => import(/* webpackChunkName: "System18/DGN.svg" */"./System18/DGN"))
  },
  "System18/GFN": {
    name: "GFN",
    group: "System18",
    Component: lazy(() => import(/* webpackChunkName: "System18/GFN.svg" */"./System18/GFN"))
  },
  "System18/KKN": {
    name: "KKN",
    group: "System18",
    Component: lazy(() => import(/* webpackChunkName: "System18/KKN.svg" */"./System18/KKN"))
  },
  "System18/PGS": {
    name: "PGS",
    group: "System18",
    Component: lazy(() => import(/* webpackChunkName: "System18/PGS.svg" */"./System18/PGS"))
  },
  "System18/PHX": {
    name: "PHX",
    group: "System18",
    Component: lazy(() => import(/* webpackChunkName: "System18/PHX.svg" */"./System18/PHX"))
  },
  "System18/SPX": {
    name: "SPX",
    group: "System18",
    Component: lazy(() => import(/* webpackChunkName: "System18/SPX.svg" */"./System18/SPX"))
  },
  "UP0": {
    name: "UP0",
    Component: lazy(() => import(/* webpackChunkName: "UP0.svg" */"././UP0"))
  },
  "Virginian": {
    name: "Virginian",
    Component: lazy(() => import(/* webpackChunkName: "Virginian.svg" */"././Virginian"))
  },
  "VTRR": {
    name: "VTRR",
    Component: lazy(() => import(/* webpackChunkName: "VTRR.svg" */"././VTRR"))
  },
  "webdev/angular": {
    name: "angular",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/angular.svg" */"./webdev/angular"))
  },
  "webdev/chrome": {
    name: "chrome",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/chrome.svg" */"./webdev/chrome"))
  },
  "webdev/css3": {
    name: "css3",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/css3.svg" */"./webdev/css3"))
  },
  "webdev/django": {
    name: "django",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/django.svg" */"./webdev/django"))
  },
  "webdev/edge": {
    name: "edge",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/edge.svg" */"./webdev/edge"))
  },
  "webdev/ember": {
    name: "ember",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/ember.svg" */"./webdev/ember"))
  },
  "webdev/firefox": {
    name: "firefox",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/firefox.svg" */"./webdev/firefox"))
  },
  "webdev/html5": {
    name: "html5",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/html5.svg" */"./webdev/html5"))
  },
  "webdev/npm": {
    name: "npm",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/npm.svg" */"./webdev/npm"))
  },
  "webdev/rails": {
    name: "rails",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/rails.svg" */"./webdev/rails"))
  },
  "webdev/react": {
    name: "react",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/react.svg" */"./webdev/react"))
  },
  "webdev/safari": {
    name: "safari",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/safari.svg" */"./webdev/safari"))
  },
  "webdev/symfony": {
    name: "symfony",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/symfony.svg" */"./webdev/symfony"))
  },
  "webdev/vue": {
    name: "vue",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/vue.svg" */"./webdev/vue"))
  },
  "webdev/webpack": {
    name: "webpack",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/webpack.svg" */"./webdev/webpack"))
  },
  "webdev/yarn": {
    name: "yarn",
    group: "webdev",
    Component: lazy(() => import(/* webpackChunkName: "webdev/yarn.svg" */"./webdev/yarn"))
  }
};
