import theme_broggles1817 from "./broggles1817.json";
import theme_broggles1836jr from "./broggles1836jr.json";
import theme_broggles1849 from "./broggles1849.json";
import theme_broggles18EU from "./broggles18EU.json";
import theme_broggles18TK from "./broggles18TK.json";
import theme_brogglesmex from "./brogglesmex.json";
import theme_carth from "./carth.json";
import theme_chesapeake from "./chesapeake.json";
import theme_ColourBlindFriendly from "./ColourBlindFriendly.json";
import theme_dtg from "./dtg.json";
import theme_gmt from "./gmt.json";
import theme_gtg_1861_67 from "./gtg-1861-67.json";
import theme_hartland from "./hartland.json";
import theme_moon from "./moon.json";
import theme_ps18xx from "./ps18xx.json";
import theme_traxx from "./traxx.json";

const themes = {
  "broggles1817": theme_broggles1817,
  "broggles1836jr": theme_broggles1836jr,
  "broggles1849": theme_broggles1849,
  "broggles18EU": theme_broggles18EU,
  "broggles18TK": theme_broggles18TK,
  "brogglesmex": theme_brogglesmex,
  "carth": theme_carth,
  "chesapeake": theme_chesapeake,
  "ColourBlindFriendly": theme_ColourBlindFriendly,
  "dtg": theme_dtg,
  "gmt": theme_gmt,
  "gtg-1861-67": theme_gtg_1861_67,
  "hartland": theme_hartland,
  "moon": theme_moon,
  "ps18xx": theme_ps18xx,
  "traxx": theme_traxx
};

export default themes;
