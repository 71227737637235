let games = {};

games["1858"] = {
  id: "1858",
  slug: "1858",
  file: "1858.json",
  title: "1858",
  subtitle: "The Railways of Iberia",
  designer: "Ian Wilson",
  publisher: "aag",
  minPlayers: 3,
  maxPlayers: 6,
};
games["1861"] = {
  id: "1861",
  slug: "1861",
  file: "1861.json",
  title: "1861",
  subtitle: "The Railways of the Russian Empire",
  designer: "Ian Wilson",
  publisher: "gtg",
  minPlayers: 3,
  maxPlayers: 6,
};
games["1867"] = {
  id: "1867",
  slug: "1867",
  file: "1867.json",
  title: "1867",
  subtitle: "The Railways of Canada",
  designer: "Ian Wilson",
  publisher: "gtg",
  minPlayers: 3,
  maxPlayers: 6,
};
games["1871BC"] = {
  id: "1871BC",
  slug: "1871BC",
  file: "1871BC.json",
  title: "1871: British Columbia",
  subtitle: "Confederation Railways",
  designer: "Brennan Sheremeto",
  publisher: "self",
  minPlayers: 2,
  maxPlayers: 4,
};
games["1883ExpressdOrient"] = {
  id: "1883ExpressdOrient",
  slug: "1883ExpressdOrient",
  file: "1883ExpressdOrient.json",
  title: "1883 Express d'Orient",
  designer: "Anthony Fryer",
};
games["1888"] = {
  id: "1888",
  slug: "1888",
  file: "1888.json",
  title: "1888",
  designer: "Anthony Fryer",
};
games["1889"] = {
  id: "1889",
  slug: "1889",
  file: "1889.json",
  title: "1889",
  subtitle: "History of Shikoku Railways",
  designer: "Yasutaka Ikeda",
  publisher: "gtg",
  minPlayers: 2,
  maxPlayers: 6,
};
games["18EB"] = {
  id: "18EB",
  slug: "18EB",
  file: "18EB.json",
  title: "18EB",
  designer: "Michael Carter, Jeremy Long and Alexandra Sime",
  publisher: "self",
  minPlayers: 2,
  maxPlayers: 4,
};
games["18GJ"] = {
  id: "18GJ",
  slug: "18GJ",
  file: "18GJ.json",
  title: "18 Grand Junction",
  designer: "Anthony Fryer",
};
games["18LA"] = {
  id: "18LA",
  slug: "18LA",
  file: "18LA.json",
  title: "18 Los Angeles",
  subtitle: "Railroading in the City of Angels",
  designer: "Tony Fryer",
  minPlayers: 2,
  maxPlayers: 4,
};
games["18NC"] = {
  id: "18NC",
  slug: "18NC",
  file: "18NC.json",
  title: "18NC",
  subtitle: "Railroads of the Tar Heel State",
  designer: "Matt Wilson",
  publisher: "self",
  minPlayers: 3,
  maxPlayers: 5,
};
games["18SJ"] = {
  id: "18SJ",
  slug: "18SJ",
  file: "18SJ.json",
  title: "18SJ",
  subtitle: "Let there be rail",
  designer: "Örjan Wennman",
  minPlayers: 2,
  maxPlayers: 6,
};
games["18TE"] = {
  id: "18TE",
  slug: "18TE",
  file: "18TE.json",
  title: "18TE",
  subtitle: "Eisenbahnen im Tecklenburger Land",
  designer: "Herbert Harengel",
  publisher: "self",
  minPlayers: 2,
  maxPlayers: 3,
};
games["18Test"] = {
  id: "18Test",
  slug: "18Test",
  file: "18Test.json",
  title: "18Test",
  subtitle: "18xx-Maker Test File",
  designer: "Christopher Giroir",
  minPlayers: 1,
  maxPlayers: 6,
};
games["18TraXX2020"] = {
  id: "18TraXX2020",
  slug: "18TraXX2020",
  file: "18TraXX2020.json",
  title: "18TraXX",
  subtitle: "2020: Great Plains Railroading",
  designer: "Michael Carter and Anthony Fryer",
  minPlayers: 2,
  maxPlayers: 6,
};
games["21Moon"] = {
  id: "21Moon",
  slug: "21Moon",
  file: "21Moon.json",
  title: "21Moon",
  designer: "Jonas Jones",
  minPlayers: 3,
  maxPlayers: 6,
};
games["TheOldPrince1871"] = {
  id: "TheOldPrince1871",
  slug: "TheOldPrince1871",
  file: "TheOldPrince1871.json",
  title: "The Old Prince 1871",
  subtitle: "Prototype",
  designer: "Lucas Boyd",
  minPlayers: 3,
  maxPlayers: 4,
};

export default games;
