import theme_B18TK from "./B18TK.json";
import theme_broggles from "./broggles.json";
import theme_carth from "./carth.json";
import theme_dtg from "./dtg.json";
import theme_gmt from "./gmt.json";
import theme_gtg_1861 from "./gtg-1861.json";
import theme_gtg_1867 from "./gtg-1867.json";
import theme_ps18xx from "./ps18xx.json";
import theme_rob from "./rob.json";
import theme_rob1834 from "./rob1834.json";

const themes = {
  "B18TK": theme_B18TK,
  "broggles": theme_broggles,
  "carth": theme_carth,
  "dtg": theme_dtg,
  "gmt": theme_gmt,
  "gtg-1861": theme_gtg_1861,
  "gtg-1867": theme_gtg_1867,
  "ps18xx": theme_ps18xx,
  "rob": theme_rob,
  "rob1834": theme_rob1834
};

export default themes;
